<template>
  <div>
      <!-- @onToggleOpen="handleToggleOpen" -->
      <Chat ref="chatRef"
        iconColorProp="#e6e6e6"
        messageOutColorProp="#4d9e93"
        messageInColorProp="#f1f0f0"
        messageBackgroundColorProp="#ffffff"
        :newMessagesProp="newMessages"
        :messageListProp="messageList"
        :initOpenProp="initOpen"
        @onMessageWasSent="handleMessageSent"
      />
      <br>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label font-weight-bold text-right">Message Template:</label>
        <select class="form-select" v-model="msgTemplate">
          <option v-for="mt in msgTemplateList" :key="mt.text" :value="mt.text">
              {{ mt.name }}
          </option>
        </select>

        <!-- <div class="col-sm-4 text-left">
          <select class="form-control" v-model="msgTemplate">
            <option>Order Received</option>
            <option>Terms & Conditions</option>
            <option>Payment Instructions</option>
            <option>Payment Received</option>
            <option>Order Dispatched</option>
          </select>
        </div> -->

        <div class="col-sm-4 text-left">
          <CButton color="dark" class="ml-4"  v-on:click.prevent="createMessage()">Create Message</CButton>
        </div>
      </div>

      <div align="center">
        <label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
        <scale-loader :loading="loading"></scale-loader>
      </div>

  </div>
</template>
    
<script>
  import { collection, query, where, orderBy, onSnapshot, doc, getDoc } from "firebase/firestore"

  //import { Timestamp, addDoc } from "firebase/firestore"; 
  import { db } from '../../firebaseConfig.js'
  import Chat from './Chat.vue'
  import axios from 'axios'
  import ScaleLoader from '@/views/utils/ScaleLoader'
  import { firebaseAuth } from '../../firebaseConfig.js';

  export default {
    name: 'OrderMessages',

    inheritAttrs: false,
      props: {
        orgIdProp: {
          type: String,
          default: '0'
        },
        orderProp: {},
        userIdProp: '',
      },

    components: {
        ScaleLoader,
        Chat
	  },

    data: () => {
      return {
        orgId: null,

        userId: '',
        messageList: [],
        initOpen: true,
        toggledOpen: false,
        msgTemplate: "",
        msgTemplateList: [],
        newMessages: false,

        loading: false,
			  loadingMsg: null,
      }
    },

    mounted() {
      this.orgId = this.$route.params['orgId']
      if (this.userIdProp && this.userIdProp!= ''){
        this.userId = this.userIdProp
      }
      else if (this.orderProp){
        if (this.orderProp.userId && this.orderProp.userId!='')
          this.userId = this.orderProp.userId
        else{
          this.userId = this.orderProp.nbr
        }
      } 

      this.getOrderMessageTemplates()

      if (this.userId != '')
        this.getCustomerMessages(this.userId)
  
    },

    methods: {
    
      // ---------------------------------  Chat Functions -------------------------------------

      // Get Order Message Templates
      async getOrderMessageTemplates(){
        var self = this
        
        if (self.orgId.toLowerCase() == 'ketco')
          self.orgId = '1'

        const docRef = doc(db, "Org", self.orgId, "Config", "MessageTemplates")
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
		      self.msgTemplateList = docSnap.data().orderMessages
        }

      },

      // Get customer messages from db
      async getCustomerMessages(userId){
        var self = this
        self.userId = userId
        
        if (self.orgId.toLowerCase() == 'ketco')
          self.orgId = '1'

        const q = query(collection(db, "Org", self.orgId, "Messages"), 
                        where("userId", "==", userId), 
                        orderBy("created"));

        onSnapshot(q, (querySnapshot) => {
          self.messageList = []
          querySnapshot.forEach((doc) => {
            self.messageList.push({'text': doc.data().text, 'source': doc.data().source})
          });
          self.newMessages = !self.newMessages
        });

      },

      createMessage(){
        if (!this.orderProp || this.orderProp.orderNbrShort == "")
          this.orderProp = {'orderNbrShort': '###', 'total': 0.00, }

        // Replace - orgName, orderNbr, orderTotal
        let msg = ""
        if (this.orgId == 1)
          msg = this.msgTemplate.replace('<orgName>', 'Ketco')
        else
          msg = this.msgTemplate.replace('<orgName>', this.orgId)
        
        msg = msg.replace('<orderNumber>', this.orderProp.orderNbrShort)
        msg = msg.replace('<orderTotal>', this.orderProp.total)
        this.setOutboundMessage(msg)

        // switch(this.msgTemplate) {
        //   case 'Order Received':
        //     this.setOutboundMessage("Thank you for order request. Your order number is: " + this.orderProp.orderNbrShort)
        //     break;
        //   case 'Terms & Conditions':
        //     if (this.orderProp.total < 10000.00)
        //       this.setOutboundMessage(msg + "Courier charges: Approx. Rs. 500. Please confirm if you want to proceed with order.")
        //     else
        //       this.setOutboundMessage(msg + "Please send your postal address to determine transport charges.")
        //     break;
        //   case 'Payment Instructions':
        //     this.setOutboundMessage(msg + "Google Pay: <GPay Info>" )
        //     break;
        //   case 'Payment Received':
        //     this.setOutboundMessage("Order number: " + this.orderProp.orderNbrShort + ", Payment Received.")
        //     break;
        //   case 'Order Dispatched':
        //     this.setOutboundMessage("Order number: " + this.orderProp.orderNbrShort + ", Order Shipped.")
        //     break;
        //   default:
        //     this.setOutboundMessage("Thank you.")
        // }

      },

      setOutboundMessage(message){
        this.$refs.chatRef.setOutboundMessage(message)
      },


      // Send message from you
      async handleMessageSent(message) {
        var self = this

        // Confirm if user is logged in
        const admin = firebaseAuth.currentUser
        if (admin == null) {
          self.$alert("Please 'Sign in with Google'", '', 'success', { confirmButtonColor: '#161c2d' })
          return
        }

        // Set loading message
        // self.loading = true
        // self.loadingMsg = 'Sending Notification...'

        const authToken = await admin.getIdToken()
        let postJson = {
                  'text': message, 
                  'userId': self.userId
        }

        await axios({
          method: 'POST',
          baseURL: process.env.VUE_APP_ROOT_API,
          url: '/SendMessageToUser/',
          data: postJson,
          headers: {
            'Authorization': 'Bearer ' + authToken,
            'OrgId': self.orgId,
            'Content-Type': 'application/json'
          }
        })
          .then(async function (response) {
            if (response.data.err != '') {
              self.$alert(response.data.err, '', 'error', { confirmButtonColor: '#161c2d' })
              console.log('sendMessage - error: ' + response.data.err)
            } else {
              // No need to set message over here. Query snapshot is getting the messages already.
              //self.messageList.push({'text': message, 'source': 'B'})
              //self.$refs.chatRef.messageScroll()
            }
          })
          .catch(function (error) {
            self.$alert(String(error), '', 'error', { confirmButtonColor: '#161c2d' })
            // Reset loading message
            // self.loading = false
            // self.loadingMsg = null
          })
      },

      // Receive message from them (handled by you with your backend)
      handleMessageResponse(message) {
        if (message.length > 0) {
              this.messageList.push({ text: message, source: 'C' })
          }
      },

      // Chat toggled open event emitted
      // handleToggleOpen(open) {
      //   this.toggledOpen = open
      //   // connect/disconnect websocket or something
      // },
      // Audible chat response noise, use whatever noise you want
      // handleMessageResponseSound() {
      //   const audio = new Audio(incomingMessageSound)
      //   audio.addEventListener('loadeddata', () => {
      //     audio.play()
      //   })
      // },

    }
  }

</script>
    
<style scoped lang="css">

</style>